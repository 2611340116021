import React from 'react';
import NewsletterForm from '@/components/common/v4/newsletter-form';

const FormSection = () => {
  return (
    <div className="flex flex-col justify-end py-14 lg:py-20" id="report">
      <div className="mx-auto h-full w-full max-w-[1280px] px-4">
        <div className="-mx-4 flex h-full flex-wrap items-center justify-between">
          <div className="mb-6 w-full px-4 md:w-1/2 md:max-w-xl lg:w-7/12">
            <h3 className="my-6 font-serif text-[32px] font-normal leading-tight md:text-[36px] lg:text-[48px]">
              🔮 Read the full report
            </h3>
            <div className="text-base md:text-sm lg:text-base">
              <p className="my-4">You're on your way to learning:</p>
              <ul className="list-disc pl-4 leading-10">
                <li>Hallucination rankings by task type</li>
                <li>Correctness and Context Adherence for each model</li>
                <li>Evaluation methodology for hallucinations</li>
              </ul>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-5/12">
            <div className="border border-hi-3 px-4 pb-4 lg:px-6 lg:pb-6">
              <NewsletterForm
                portalId={'23114811'}
                formId={'2f18f798-cbd5-4b3b-b1a5-7df18ab5a7a1'}
                cssClass={'hindex-hero'}
                redirectUrl="/ty/hallucinationindex"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSection;
